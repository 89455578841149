#maplink {
  display: block;
  margin: auto;
  border: 0;
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
  background: url('https://zizkovnezastavis.cz/kauzy/wp-content/themes/kauzy/img/sipka.svg') no-repeat;
  background-size: 100px 100px;
  width: 100px;
  height: 100px;
  transform: rotate(90deg);
}
.entry-content p {
  margin-left: auto;
}
.site-inner {
  max-width: 924px;
  min-height: 100vh;
  min-height: calc(100vh - 42px);
}
#map {
  height: 100vh;
  height: calc(100vh - 42px);
}
.content-area {
  float: none;
  width: auto;
  margin: auto;
}
.site-description {
  margin-top: 0;
}
.btn {
  background: #1a1a1a;
  border: 0;
  border-radius: 2px;
  color: #fff;
  font-weight: 700;
  line-height: 1;
  padding: 0.84375em 0.875em 0.78125em;
  text-transform: uppercase;
}
.bth:focus,
.btn:hover,
.btn:active {
  background-color: #00ad00;
  color: #fff;
}
.info-window h2 {
  font-size: 28px;
  font-size: 1.75rem;
}
.info-window p {
  font-size: 16px;
  font-size: 1rem;
}
@media screen and (min-width: 61.5625em) {
  .site-header {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
}

.logo-sz {
  margin-top: 1.75em;
}
.logo-sz a {
  display: block;
}
